import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/root/Layout"
import Seo from "../../components/root/Seo"
import ArrowRight from "../../../assets/heroicons-0.4.1/optimized/outline/arrow-narrow-right.svg"

export default function About() {
  return (
    <Layout>
      <Seo title="About" />
      <div className="">
        <div className="container py-8 md:py-16 lg:py-24">
          <h1 className="text-4xl md:text-5xl leading-tight font-bold mb-3">
            About Us
          </h1>
          <h2 className="text-2xl md:text-3xl mb-5">
            We know elevating your career is hard;{" "}
            <span className="font-bold">even more so during a pandemic</span>.
          </h2>
          <p className="mb-3 text-lg">
            ArchUp founders Katie and Ioana grew increasingly concerned for the
            lack of opportunities available to students and young professionals,
            following the effects of several lockdowns, and are determined to
            help. Young people are particularly vulnerable to the disruptions
            the Covid-19 pandemic has caused, and many are now at risk of being
            left behind in education, economic opportunities and health and
            wellbeing at such a crucial time in their life development. Across
            the UK there are startling disparities between the effects on
            specific groups, with women being worst affected. ​
          </p>
        </div>
      </div>
      <div className="bg-brand-blue-light text-white">
        <div className="container py-8 md:py-16 ">
          <Link
            to="/about/our-mission"
            className="text-2xl md:text-3xl mb-5 flex items-center max-w-2xl"
          >
            <div className="text-xl md:text-2xl">
              <h1 className="text-3xl md:text-5xl leading-tight font-bold mb-3">
                Our Mission
              </h1>
              <p>Read our mission statement</p>
            </div>

            <ArrowRight className="mr-4 md:mr-0 ml-auto h-24 w-24 md:h-48 md:w-48" />
          </Link>
        </div>
      </div>
      <div className="">
        <div className="container py-8 md:py-16 ">
          <Link
            to="/about/meet-the-team"
            className="text-2xl md:text-3xl mb-5 flex items-center max-w-2xl"
          >
            <div className="text-xl md:text-2xl">
              <h1 className="text-3xl md:text-5xl leading-tight font-bold mb-3">
                Meet The Team
              </h1>
              <p>Discover the people behind ArchUp</p>
            </div>

            <ArrowRight className="mr-4 md:mr-0 ml-auto h-24 w-24 md:h-48 md:w-48" />
          </Link>
        </div>
      </div>
    </Layout>
  )
}
